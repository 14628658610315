import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import "./App.css";

const App = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    // Scene, Camera, Renderer setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Particle system setup
    const particlesCount = 1000;
    const particlesGeometry = new THREE.BufferGeometry();
    const particlesMaterial = new THREE.PointsMaterial({
      color: 0x00ff7f,
      size: 0.05,
    });

    const positions = new Float32Array(particlesCount * 3);
    for (let i = 0; i < particlesCount * 3; i++) {
      positions[i] = (Math.random() - 0.5) * 50; // Randomly position particles
    }

    particlesGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );
    const particles = new THREE.Points(particlesGeometry, particlesMaterial);
    scene.add(particles);

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
    scene.add(ambientLight);

    camera.position.z = 25;

    // Animation for the particle system
    const animate = function () {
      requestAnimationFrame(animate);

      // Rotating particles for a dynamic effect
      particles.rotation.x += 0.0005;
      particles.rotation.y += 0.001;
      renderer.render(scene, camera);
    };

    animate();

    // Cleanup on component unmount
    return () => {
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <div className="App">
      {/* Overlay text content */}
      <div className="overlay">
        <h1>Vulcan Cortex</h1>
        {/* <h2> Quantitative Trading Firm</h2> */}
        <h2>$100,000+ in Assets Under Management</h2>
        <p>Contact aviral.dhingra.2008@gmail.com if interested in investing</p>
      </div>

      {/* Three.js Visualization */}
      <div ref={mountRef} className="threejs-container"></div>
    </div>
  );
};

export default App;
